import { Col, Drawer, Form, Input, Row, Typography } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IProductLine } from '../../../../logic/Models/Requests/ProductPlanRequest';
import { ICustomTreatmentResponse } from '../../../../logic/Models/Responses/CustomTreatmentResponse';
import { ProductPlanCropType } from '../../../../logic/Models/Responses/ProductGamePlan';
import { UserPermission } from '../../../../logic/Models/Responses/UserPermissions';
import { updateProduct } from '../../../../logic/store/Plans/ProductGamePlanUpdateActions';
import { ISeries } from '../../../../logic/store/Seeds/SeedsActions';
import { RootState } from '../../../../logic/store/Store';
import { makeDispatch } from '../../../../logic/Utility/Utils';
import { ReadOnlySeriesProductRow } from './SeriesProductRow';
import { hasEffectivePermission } from '../../../../logic/store/User/AuthSlice';
import { useTheme } from 'styled-components';

interface ILocalPositioningDrawProps
{
	planId: string;
	series: ISeries;
	cropId: string;
	cropType: ProductPlanCropType;
	products: IProductLine[];
	visible: boolean;
	customTreatments?: ICustomTreatmentResponse[];
	onClose: () => any;
}

const mapStateToProps = (state: RootState, ownState: ILocalPositioningDrawProps) => ({
	UserIsGrower: hasEffectivePermission(state, UserPermission.IsGrower),

});
const mapDispatchToProps = (dispatch: any) => ({
	onUpdate: makeDispatch(dispatch, updateProduct)
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * This is a slide-out drawer that will let the user edit the local positioning for a series 
 * ( applied across all hybrids at once )
 */
export function LocalPositioningDrawer(props: ILocalPositioningDrawProps & PropsFromRedux)
{
	const { planId, series, visible, onClose, onUpdate, products, cropId, cropType, customTreatments, UserIsGrower } = props;

	const theme = useTheme();

	const [localPositioning, setLocalPositioning] = useState(props.products[0]?.LocalPositioning);

	const commitChangedText = useCallback(debounce((text) => 
	{
		// get list of hybrids 
		for (const hybrid of products)
		{
			// need batch redux update here
			onUpdate({
				planId,
				cropId,
				update: {
					LocalPositioning: text
				}
			});
		}
	}, 500), [onUpdate, planId, cropId, cropType, products]);

	const applyChangedText = useCallback((text) => 
	{
		setLocalPositioning(text);
		commitChangedText(text);
	}, [setLocalPositioning]);

	return <Drawer
		title="Local Positioning Notes"
		open={visible}
		onClose={onClose}
		width={700}
	>
		<Form layout='vertical'>
			<Row>
				<Col span={24}>
					<ReadOnlySeriesProductRow series={series} />
				</Col>
			</Row>
			<Row>
				<Col span={24} style={{ paddingTop: 32 }}>
					<label htmlFor='localPositioning' style={{
						fontSize: theme.fontSizes.large,
						fontWeight: theme.fontWeights.bold,
						fontFamily: theme.fonts.heading
					}}>
						Local Positioning
					</label>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{
						UserIsGrower ?
							<div className='localPositioning'>{localPositioning}</div>
							:
							<Input.TextArea rows={5} showCount maxLength={450} name='localPositioning' value={localPositioning} onChange={(evt) => applyChangedText(evt.currentTarget.value)} />

					}
				</Col>
			</Row>
			{customTreatments.map(customTreatment =>
			{
				const { Name, Notes, Id } = customTreatment;
				const key = `customTreatments-${Id}`;
				return <div key={[key, `-${series.seriesId}`].join('')}>
					<Row >
						<Col span={24} style={{ paddingTop: 12 }}>
							<label htmlFor={key} style={{
								fontSize: theme.fontSizes.large,
								fontWeight: theme.fontWeights.bold,
								fontFamily: theme.fonts.heading
							}}>
								{Name}
							</label>
						</Col>
					</Row>
					<Row>
						<Col span={24} className={key}>
							<Typography.Text>{Notes}</Typography.Text>
						</Col>
					</Row>
				</div>;
			})}
		</Form>

	</Drawer>;
}

export const ConnectedLocalPositioningDrawer = connector(LocalPositioningDrawer);

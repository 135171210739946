import { v4 as uuidv4 } from 'uuid';
import { ISeriesWithHybrid } from '../Seeds/SeedsActions';
import { IProductLine } from '../../Models/Requests/ProductPlanRequest';
import { IHybridResponse } from '../../Models/Responses/SeedResponse';
import { IPlanEditState, IProductGamePlanWithEdits } from './ProductGamePlanSlice';
import { ITreatmentDictionary } from './ProductGamePlanActions';
import { ITreatment } from '../../../pages/FieldActivities/ProductGamePlan/Editor/SeriesProductRow';
import { ICropConfig, soyId, CropConfig, cornId } from '../Seeds/CropsSlice';
import { ICustomTreatmentResponse } from '../../Models/Responses/CustomTreatmentResponse';
import { cropBrandAssociations } from '../../../pages/FieldActivities/ProductGamePlan/CropBrandAssociation';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { ISeriesYields } from '../Seeds/PlantingRateSlice';

export const newPlanDummyId = 'new-plan-dummy-id';

export const untreated: ITreatment = { Name: 'Untreated', Price: 0 };

export const getUntreated = (): ITreatment =>
{
	// return default from treatments
	return untreated;
};

export const getDefaultTreatment = (defaultTreatmentName: string, cropTreatments: ITreatment[], customTreatments: ICustomTreatmentResponse[]): ITreatment =>
{
	let defaultTreatment: ITreatment;
	const defaultCropTreatment = cropTreatments.find(treatment => treatment.Name === defaultTreatmentName);
	const defaultCustomTreatment = customTreatments.find(treatment => treatment.Name === defaultTreatmentName);
	if (defaultCropTreatment)
	{
		defaultTreatment = defaultCropTreatment;
	}
	else if (defaultCustomTreatment)
	{
		defaultTreatment = {
			Name: defaultCustomTreatment.Name,
			Price: defaultCustomTreatment.PricePerBag,
			CustomTreatmentId: defaultCustomTreatment.Id
		};
	}
	else
	{
		defaultTreatment = getUntreated();
	}

	return defaultTreatment;
};

/**
 * An internal helper function to combine different sources of data together so we can track user edits easily
 */
export function createLocalEditingState(
	productGamePlan: IProductGamePlanWithEdits,
	hybridLookup: { [key: string]: IHybridResponse; },
	seriesLookup: {
		[key: string]: ISeriesWithHybrid;
	},
	treatments: Partial<ITreatmentDictionary>,
	growerZip: string = '',
	defaultTreatmentName: string = ''
)
{
	const { originalData, localEditingState } = productGamePlan;
	const newState: IPlanEditState = {
		...(localEditingState ? localEditingState : { series: {}, products: {}, recommendationOptions: {} })
	};

	// Initialize recommendation options
	for(const brandAssociation of cropBrandAssociations)
	{
		const cropAssociation = brandAssociation.cropId + brandAssociation.brandApplication;
		newState.recommendationOptions[cropAssociation] = {
			acres: CropConfig()[brandAssociation.cropId].defaultFieldlessAcres,
			fungicide: false,
			irrigated: false,
			isFilterApplied: false,
			performance: (cropAssociation.indexOf('Enogen') > -1 || cropAssociation.indexOf(soyId) > -1) ? 0 : 5,
			rmMax: undefined,
			rmMin: undefined,
			seedsRequired: CropConfig()[brandAssociation.cropId].defaultFieldlessSeedsRequired,
			selectedTraits: [],
			targetYield: CropConfig()[brandAssociation.cropId].defaultFieldlessYield,
			topEndYield: (cropAssociation.indexOf('Enogen') > -1 || cropAssociation.indexOf(soyId) > -1) ? 0 : 5,
			zip: growerZip,
		};

		// If we have an existing plan, set the existing recommendation settings (if there are any)
		if (originalData && originalData.RecommendationSettings)
		{
			if (cropAssociation.indexOf('Enogen') > -1)
			{
				newState.recommendationOptions[cropAssociation].acres = originalData.RecommendationSettings.EnogenAcres;
				newState.recommendationOptions[cropAssociation].irrigated = originalData.RecommendationSettings.EnogenIrrigated;
				newState.recommendationOptions[cropAssociation].seedsRequired = originalData.RecommendationSettings.EnogenRequiredSeeds;
				newState.recommendationOptions[cropAssociation].targetYield = originalData.RecommendationSettings.EnogenTargetYield;
				newState.recommendationOptions[cropAssociation].zip = originalData.RecommendationSettings.EnogenZipCode;
			}
			else if (cropAssociation.indexOf(cornId) > -1 && cropAssociation.indexOf('Any') > -1)
			{
				newState.recommendationOptions[cropAssociation].acres = originalData.RecommendationSettings.CornAcres;
				newState.recommendationOptions[cropAssociation].irrigated = originalData.RecommendationSettings.CornIrrigated;
				newState.recommendationOptions[cropAssociation].seedsRequired = originalData.RecommendationSettings.CornRequiredSeeds;
				newState.recommendationOptions[cropAssociation].targetYield = originalData.RecommendationSettings.CornTargetYield;
				newState.recommendationOptions[cropAssociation].zip = originalData.RecommendationSettings.CornZipCode;
			}
			else if (cropAssociation.indexOf(soyId) > -1)
			{
				newState.recommendationOptions[cropAssociation].acres = originalData.RecommendationSettings.SoybeanAcres;
				newState.recommendationOptions[cropAssociation].irrigated = originalData.RecommendationSettings.SoybeanIrrigated;
				newState.recommendationOptions[cropAssociation].seedsRequired = originalData.RecommendationSettings.SoybeanRequiredSeeds;
				newState.recommendationOptions[cropAssociation].targetYield = originalData.RecommendationSettings.SoybeanTargetYield;
				newState.recommendationOptions[cropAssociation].zip = originalData.RecommendationSettings.SoybeanZipCode;
			}
		}

		if (originalData && originalData.AgronomicSettings && originalData.AgronomicSettings.length > 0)
		{
			const foundAgSetting = originalData.AgronomicSettings.find(agSetting => 
				agSetting.BrandApplication === brandAssociation.brandApplication && agSetting.CropId === brandAssociation.cropId);
			if (foundAgSetting)
			{
				newState.recommendationOptions[cropAssociation].fungicide = foundAgSetting.Fungicide;
				newState.recommendationOptions[cropAssociation].isFilterApplied = foundAgSetting.IsFilterApplied;
				newState.recommendationOptions[cropAssociation].performance = foundAgSetting.ConsistencyPerf;
				newState.recommendationOptions[cropAssociation].rmMax = foundAgSetting.RmMax;
				newState.recommendationOptions[cropAssociation].rmMin = foundAgSetting.RmMin;
				newState.recommendationOptions[cropAssociation].topEndYield = foundAgSetting.TopEndYield;
				newState.recommendationOptions[cropAssociation].selectedTraits = foundAgSetting.Traits.split(',');
			}
		}
	}

	// if recommendations don't exist, return original
	if (!originalData?.recommendations || !hybridLookup || !seriesLookup)
	{
		return newState;
	}

	for (const rec of originalData.recommendations)
	{
		for (const product of rec.products)
		{
			const {
				crop_id: cropId,
				crop_type: cropType
			} = rec;
			const { product_id: HybridId } = product;
			const hybrid = hybridLookup[product.product_id];
			if (!hybrid)
			{
				break;
			}
			const series = seriesLookup[hybrid.SeriesId];
			const recTreatment: ITreatment = product.treatment;

			let previouslyEditedProductInputs = {};
			const defaultTreatment: ITreatment = treatments[cropId]?.find(t => t.Name.toLocaleLowerCase() === defaultTreatmentName.toLocaleLowerCase()) ||
				treatments[cropId]?.find(t => t.Name.toLocaleLowerCase() === 'untreated');

			// Generate a unique id for this product line
			const productKey = uuidv4();
			// capture any local changes made to inputs to apply to updated product data
			if (localEditingState && localEditingState.products[productKey])
			{
				const editingProduct = localEditingState.products[productKey].product;
				previouslyEditedProductInputs = {
					AppliedArea: editingProduct.AppliedArea,
					Quantity: editingProduct.Quantity,
					QuantityRequired: editingProduct.QuantityRequired,
					SeedingRate: editingProduct.SeedingRate,
					TotalPrice: editingProduct.TotalPrice,
					YieldTarget: editingProduct.YieldTarget,
					Treatment: editingProduct.Treatment || (cropId === soyId ? defaultTreatment : undefined),
				};

				return localEditingState;
			}

			// In order to do appropriate local editing and visualization here, we combine the hybrid raw information from the seed list to the 
			// product lines in the product plan.
			// This is because some elements (eg total price) will be calculated from a mix like quantity (product plan) * price (seed list)
			// This isn't all actually shown here yet as price and localpositioning will be added soon.

			newState.series[series.seriesId] = {
				cropId, cropType, series
			};
			newState.products[productKey] = {
				key: productKey,
				cropId,
				cropType,
				seriesId: series.seriesId,
				hybridId: hybrid.Id,
				product: {
					Key: productKey,
					HybridId,
					PriceRate: product.price_rate,
					PriceUnit: product.price_unit,
					LocalPositioning: product.statement,
					AppliedArea: product.applied_area,
					Quantity: product.quantity,
					QuantityRequired: product.total_quantity_required,
					SeedingRate: product.seed_rate,
					TotalPrice: product.total_price,
					Treatment: cropId === soyId ? recTreatment ?? defaultTreatment : undefined,
					YieldTarget: product.yield_target,
					...previouslyEditedProductInputs
				} as IProductLine
			};
		}
	}

	return newState;
}

export function updateProductData({
	productData: productDataFromProps,
	update,
	cropConfig,
	cropId,
	defaultGrowerSeedingRate
}: {
	productData: IProductLine;
	update: Partial<IProductLine>;
	cropConfig: ICropConfig;
	cropId: string;
	defaultGrowerSeedingRate?: number;
}): IProductLine
{
	const isSoy = cropId === soyId;
	const productData = {
		...productDataFromProps,
		...update
	};

	if (!productData.SeedingRate)
	{
		// Get the grower default rate if there is one, otherwise take the 'default default'
		productData.SeedingRate = defaultGrowerSeedingRate || cropConfig.seedsPerAcre;
	}

	// Now that we have the seeding rate, calculate a quantity
	const quantity = (productData.AppliedArea * productData.SeedingRate) / cropConfig.seedsPerBag;

	// If the user changed area or rate, make sure we update bags
	if (update.AppliedArea || update.SeedingRate) 
	{
		productData.Quantity = Math.ceil(quantity);
	}
	if (update.Quantity)
	{
		productData.AppliedArea = Math.round((((productData.Quantity * cropConfig.seedsPerBag) / productData.SeedingRate) + Number.EPSILON) * 100) / 100;

	}

	// Calculate quantity required
	productData.QuantityRequired = productData.Quantity;

	// Calculate the price
	if (productData.AppliedArea && productData.PriceRate)
	{
		let variablePrice: number;
		const treatmentPrice = productData.Treatment?.Price ?? 0;

		// The price should always be rounded to a cent
		if (isSoy)
		{
			// The product is priced per bag
			variablePrice = (productData.Quantity * productData.PriceRate)
				// The treatment is priced per unit/bag
				+ (productData.Quantity * treatmentPrice);
		}
		else
		{
			// Both are per acre, add any treatment price in
			variablePrice = productData.AppliedArea * (productData.PriceRate + treatmentPrice);
		}

		// The price should always be rounded to a cent
		productData.TotalPrice = Math.round((variablePrice * 100) + Number.EPSILON) / 100;
	}
	return productData;
}

/**
 * This is a simpler function of the same from ProductGamePlanUpdateActions - updateYieldTarget
 * So that it can be called from within another reducer without needing async/await
 */
export interface ISimpleUpdateYieldActionProps
{
	planId: string;
	productKey: string;
	cropId: string;
	yieldTarget: number;
	seriesName: string;	
	selectedGrower: IGrowerResponse;
	plantingRates: ISeriesYields;
	localEditingState: IPlanEditState
}

export const simpleUpdateYieldTarget = (request: ISimpleUpdateYieldActionProps) : IProductLine =>
{
	const { cropId, localEditingState, planId, plantingRates, productKey, selectedGrower, seriesName, yieldTarget } = request;
	const cropConfig = CropConfig()[cropId];

	const { yieldInterval, hasPlantingRateCalculator } = CropConfig()[cropId];
	const productData = {
		...localEditingState.products[productKey].product,
		YieldTarget: yieldTarget
	};

	// Accumulate all changes to pass to updateProductData to make sure
	// it has an opportunity to react to them.
	const update: Partial<IProductLine> = {
		YieldTarget: yieldTarget
	};

	if (hasPlantingRateCalculator)
	{
		const yieldTargetKey = Math.round(yieldTarget / yieldInterval) * yieldInterval;
		if (plantingRates[seriesName]) 
		{
			update.SeedingRate = plantingRates[seriesName][yieldTargetKey];
		}
	}

	const updateResult = updateProductData({
		cropId,
		productData,
		update,
		cropConfig,
		defaultGrowerSeedingRate: cropConfig.defaultSeedingRate
	});

	return updateResult;
};

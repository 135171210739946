
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { getCurrentActingUser } from '../User/AuthSlice';
import { IFileDownloadUrlResponse, IFileListingResponse, IFileUploadUrlResponse } from '../../Models/Responses/FileResponses';
import { IFileUploadTracker } from './FileSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IFileUploadUrlRequest
{
	FileName: string;
}

export const getUploadUrlForFile = createTracedAsyncThunk<IFileUploadUrlResponse, IFileUploadUrlRequest, { dispatch: AppDispatch, state: RootState }>(
	'file/getuploadUrl',
	async (context, request: IFileUploadUrlRequest, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const api = new Api('/api/4', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<IFileUploadUrlResponse>(
				`users/${getCurrentActingUser(currentState, true).UserId}/growers/
				${currentState.ui.SelectedGrowerId}/Files/MachineData/UploadRequest?fileName=${request.FileName}`
			);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IFileDownloadRequest
{
	JobId: string;
}

export const getDownloadUrlForFile = createTracedAsyncThunk<IFileDownloadUrlResponse, IFileDownloadRequest, { dispatch: AppDispatch, state: RootState }>(
	'file/getdownloadUrl',
	async (context, request: IFileDownloadRequest, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const api = new Api('/api/4', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<IFileDownloadUrlResponse>(
				`users/${getCurrentActingUser(currentState, true).UserId}/growers/
				${currentState.ui.SelectedGrowerId}/Files/MachineData/FileLink/${request.JobId}`
			);

			if (response.ErrorCode === null && response.Success)
			{
				if (response.Data && response.Data.PresignedUri)
				{
					window.open(response.Data.PresignedUri, '_blank');
				}
				else
				{
					return thunkAPI.rejectWithValue('There was no pre-signed url available for the requested file.');
				}
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const uploadFileToUrl = createTracedAsyncThunk<string, File, { dispatch: AppDispatch, state: RootState }>(
	'file/uploadFileToUrl',
	async (context, request: File, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const api = new Api('/api/4', currentState.auth.userAuthToken, context);
			const fileUploadList = currentState.file.FileUploadList;
			const formData = new FormData();
			let fileUploadTracker: IFileUploadTracker = undefined;
			if (fileUploadList && fileUploadList.length > 0)
			{
				fileUploadTracker = fileUploadList.find(f => f.File.name === request.name);
				const fieldsKeys = Object.keys(fileUploadTracker.FileUploadUrlResponse.fields);
				if (fieldsKeys && fieldsKeys.length > 0)
				{
					fieldsKeys.forEach(key =>
					{
						formData.append(key.replaceAll('_','-'), fileUploadTracker.FileUploadUrlResponse.fields[key]);
					});
				}
			}
			// Add the File
			formData.append('file', request);

			const response = await api.uploadFormDataAsync(formData, fileUploadTracker.FileUploadUrlResponse.url, true, true, thunkAPI.signal);

			// Returns no content - 204 status if it was a success
			if (response.status === 204)
			{
				return request.name;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getUploadedFilesForUser = createTracedAsyncThunk<IFileListingResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'file/getListing',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const api = new Api('/api/4', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<IFileListingResponse[]>(
				`users/${getCurrentActingUser(currentState, true).UserId}/growers/${currentState.ui.SelectedGrowerId}/Files/MachineData/FileList`
			);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { ICropResponse } from '../../Models/Responses/CropsResponse';
import { ICropYearsResponse, IOrgByCropYearResponse } from '../../Models/Responses/CropYearsResponse';
import { themeGet } from '@styled-system/theme-get';
import { DefaultTheme } from 'styled-components';
import { createTracedAsyncThunk } from '../../../tracing/trace';
export const cornId = 'eefafa24-0018-4387-b2e3-175f64387102';
export const soyId = '57f62010-6713-4aa3-a652-9fd280614591';
export const unknownId = '0b5080b8-bd65-44f0-9d1e-b854c932b1e6';

/**
 * Ideally we will turn this into an API driven from the server
 */
export interface ICropConfig
{
	bagsPerToteBag?: number;
	canEditSeedingRate: boolean;
	competitorRmMin: number;
	competitorRmMax: number;
	cropName: string;
	cubitsPerBag: number;
	defaultFieldlessAcres: number;
	defaultFieldlessSeedsRequired: number;
	defaultFieldlessYield: number;
	defaultSeedingRate: number;
	hasPlantingRateCalculator?: boolean;
	hasYieldTarget: boolean;
	minSeedingRate: number;
	maxSeedingRate: number;
	maxYield?: number;
	maxVRSYield?: number;
	minYield?: number;
	priceUnits: string;
	seedsPerAcre: number;
	seedsPerBag: number;
	yieldInterval?: number;
	variableRateYieldInterval: number;
	maxScriptColor?: string;
}

export interface ICropConfigWithTheme extends ICropConfig
{
	maxScriptColor: string;
}

export function CropConfig(): { [cropId: string]: ICropConfig };
export function CropConfig(theme: DefaultTheme): { [cropId: string]: ICropConfigWithTheme};
export function CropConfig(theme?: DefaultTheme): { [cropId: string]: ICropConfig }
{
	const cornConfig: ICropConfig = {
		canEditSeedingRate: true,		
		competitorRmMax: 130,
		competitorRmMin: 70,
		cropName: 'Corn',
		cubitsPerBag: 45,
		/** These 3 settings are used in Product Plan Fieldless Recommendation settings as the default values for Corn*/
		defaultFieldlessAcres: 160,
		defaultFieldlessSeedsRequired: 3,
		defaultFieldlessYield: 200,
		/** End Fieldless defaults */
		defaultSeedingRate: 32000,
		minSeedingRate: 10000, // This is the CropEdge minimum
		maxSeedingRate: 50000, // This is the CropEdge maximum
		hasPlantingRateCalculator: true,
		hasYieldTarget: true,
		maxYield: 300,
		maxVRSYield: 300, // used when VRS sends a planting rate request to the planting rate engine for a larger yield range
		minYield: 150,
		priceUnits: 'ac',
		seedsPerAcre: 42000,
		seedsPerBag: 80000,
		yieldInterval: 5,
		variableRateYieldInterval: 1, // used when VRS sends a planting rate request to the planting rate engine for a larger yield range
		maxScriptColor: theme?.colors.secondary, // Color for MaxScript crop plan tab
	};

	const soyConfig: ICropConfig = {
		bagsPerToteBag: 40,
		canEditSeedingRate: true,
		competitorRmMax: 8.0,
		competitorRmMin: 0.0,
		cropName: 'Soybean',
		cubitsPerBag: 40,
		/** These 3 settings are used in Product Plan Fieldless Recommendation settings as the default values for Soy*/
		defaultFieldlessAcres: 300,
		defaultFieldlessSeedsRequired: 2,
		defaultFieldlessYield: 60,
		/** End Fieldless defaults */
		defaultSeedingRate: 140000,
		minSeedingRate: 60000, // This is the CropEdge minimum
		maxSeedingRate: 200000, // This is the CropEdge maximum
		hasYieldTarget: false,
		maxYield: 100,
		maxVRSYield: 100, // used when VRS sends a planting rate request to the planting rate engine for a larger yield range
		minYield: 10,
		priceUnits: 'bag',
		seedsPerAcre: 140000,
		seedsPerBag: 140000,
		yieldInterval: 1,
		variableRateYieldInterval: 1, // used when VRS sends a planting rate request to the planting rate engine for a larger yield range
		maxScriptColor: theme?.colors.blueLM, // Color for MaxScript crop plan tab
	};

	const combinedConfig: { [cropId: string]: ICropConfig } = {
		[cornId]: cornConfig,
		[soyId]: soyConfig,
	};

	return combinedConfig;
}

export const getCrops = createTracedAsyncThunk<ICropResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'get/crops',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<ICropResponse[]>('seeds/crops');

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getCropYear = createTracedAsyncThunk<IOrgByCropYearResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'get/cropYear',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/5/cropyears', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<IOrgByCropYearResponse[]>('byorg/active');

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ICropState
{
	isError: boolean;
	isLoading: boolean;
	errorMessage: string;
	Crops: ICropResponse[];
	CropYears: IOrgByCropYearResponse[];
	CropYear: string;
}

export const initialState: ICropState = {
	isError: false,
	isLoading: false,
	errorMessage: undefined,
	Crops: [],
	CropYears: [],
	CropYear: undefined,
};

export const cropsSlice = createSlice({
	name: 'crops',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state.Crops = [];
			state.isError = false;
			state.isLoading = false;
			state.errorMessage = undefined;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		},
		setCropYear: (state, { payload }: PayloadAction<string>) =>
		{
			state.CropYear = payload;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getCrops.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCrops.fulfilled, (state, { payload }: PayloadAction<ICropResponse[]>) =>
		{
			state.isLoading = false;
			state.Crops = payload;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCrops.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			state.Crops = [];
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Crops. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of Crops. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Crops. Please refresh and try again.';
			}
		});
		builder.addCase(getCropYear.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCropYear.fulfilled, (state, { payload }: PayloadAction<IOrgByCropYearResponse[]>) =>
		{
			state.isLoading = false;
			state.CropYears = payload;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCropYear.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			state.CropYear = undefined;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the current crop year. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the current crop year. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the current crop year. Please refresh and try again.';
			}
		});
	},
});

export const { clearState, clearError, setCropYear } = cropsSlice.actions;